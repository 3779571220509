import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { Menu } from './menu';
import Roles from '../models/roles';
import EntityTypes from '../models/entityTypes';

export interface MenuState {
    items: Menu[];
}

const initialState: MenuState = {
    items: [
        {
            path: 'todo', icon: 'fa fa-dashboard', title: 'To Do', roles: [Roles.Reviewer],
            children: [
                { path: '/todo/reviews', title: 'Projects', entityType: EntityTypes.Project, roles: [Roles.Reviewer] },
                // { path: '/dashboard/unitEvaluations', title: 'Unit Evals', roles: [Roles.Reviewer] },
                // { path: '/dashboard/all-work', title: 'All Work', roles: [Roles.Admin] },
            ]
        },
        // {
        //     path: '/partners', icon: 'fa fa-building', title: 'Partners', entityType: EntityTypes.Partner, roles: [Roles.Analyst],
        //     // children: [
        //     //     { path: '/customers/all', title: 'All' },
        //     //     { path: '/customers/active', title: 'Active' },
        //     //     { path: '/customers/inactive', title: 'Inactive' }
        //     // ]
        // },
        {
            //path: '/projects', 
            icon: 'fa-light fa-file-contract', title: 'Projects', entityType: EntityTypes.Project, roles: [Roles.Analyst],
            children: [
                { path: '/projects?view=recent', title: 'Recent' },
                { path: '/projects?view=mine', title: 'Mine' },
                { path: '/projects?view=all', title: 'All', roles: [Roles.Admin], },
            ],
        },
        {
            path: '/resources', icon: 'fa-light fa-books', title: 'Texts', entityType: EntityTypes.Resource, roles: [Roles.Analyst],
        },
        {
            path: '/reviews', icon: 'fa-duotone fa-magnifying-glass-chart', title: 'Reviews', entityType: EntityTypes.Review, roles: [Roles.Analyst, Roles.Reviewer],
        },
        {
            // path: '/reports',
            title: 'Reports',
            icon: 'fa fa-chart-simple',
            roles: [Roles.Admin],
            children: [
                {
                    path: '/reports/unit', title: 'Unit',
                },
                {
                    path: '/reports/heatmap', title: 'Heat Map',
                },
                {
                    path: '/reports/resourceQuality', title: 'Resource Quality',
                },
                {
                    path: '/reports/coherency', title: 'Coherency',
                },
            ]
        },
        {
            //path: '/settings',
            title: 'Settings',
            icon: 'fa fa-cogs',
            roles: [Roles.Admin],
            children: [
                {
                    path: '/partners', icon: 'fa fa-building', title: 'Partners', entityType: EntityTypes.Partner, roles: [Roles.Analyst],
                    // children: [
                    //     { path: '/customers/all', title: 'All' },
                    //     { path: '/customers/active', title: 'Active' },
                    //     { path: '/customers/inactive', title: 'Inactive' }
                    // ]
                },
                {
                    path: '/settings/users', icon: 'fa fa-user', title: 'Users', entityType: EntityTypes.User
                },
                {
                    path: '/settings/fields', icon: 'fa fa-pen-field', title: 'Fields'
                },
                {
                    path: '/settings/labels', icon: 'fa fa-tag', title: 'Labels'
                },
                {
                    path: '/settings/tags', icon: 'fa fa-thumbtack', title: 'Tags'
                },
            ]
        },
    ],
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    //increment: (state) => {
    //  // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //  // doesn't actually mutate the state because it uses the Immer library,
    //  // which detects changes to a "draft state" and produces a brand new
    //  // immutable state based off those changes
    //  state.value += 1;
    //},
    //decrement: (state) => {
    //  state.value -= 1;
    //},
    //// Use the PayloadAction type to declare the contents of `action.payload`
    //incrementByAmount: (state, action: PayloadAction<number>) => {
    //  state.value += action.payload;
    //},
  },
});

// export const { /*increment, decrement, incrementByAmount*/ } = menuSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMenu = (state: RootState) => state.menu.items;

export default menuSlice.reducer;
